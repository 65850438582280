import Image from "next/image";
import Link from "next/link";

export const serializer = {
  h1: ({ children }: any) => (
    <h1 className="mb-4 text-3xl font-bold sm:text-4xl md:text-5xl lg:text-6xl">{children}</h1>
  ),
  h2: ({ children }: any) => (
    <h2 className="mb-4 text-2xl font-bold sm:text-3xl md:text-3xl lg:text-4xl">{children}</h2>
  ),
  h3: ({ children }: any) => (
    <h3 className="mb-2 text-xl font-semibold sm:text-2xl md:mb-4 md:text-2xl lg:text-3xl">{children}</h3>
  ),
  h4: ({ children }: any) => <h4 className="mb-2 text-lg font-bold sm:text-xl md:text-2xl lg:text-3xl">{children}</h4>,
  h5: ({ children }: any) => <h5 className="text-base font-medium sm:text-lg md:text-xl lg:text-2xl">{children}</h5>,
  h6: ({ children }: any) => <h6 className="text-sm font-medium sm:text-base md:text-lg lg:text-xl">{children}</h6>,
  p: ({ children }: any) => <p className="text-base-200 text-base leading-relaxed">{children}</p>,
  bold: ({ children }: any) => <span className="font-bold">{children}</span>,
  italic: ({ children }: any) => <span className="text-base-200 italic">{children}</span>,
  underline: ({ children }: any) => <span className="underline">{children}</span>,
  ul: ({ children }: any) => <ul className="text-base-200 list-inside list-disc">{children}</ul>,
  ol: ({ children }: any) => <ol className="text-base-200 list-inside list-decimal">{children}</ol>,
  li: ({ children }: any) => <li className="text-base-200 text-base">{children}</li>,
  a: ({ children, href }: any) => {
    const link = href as string;
    const exampleLink = /^https?:\/\/example.com/;
    const isInternalLink = /^\/(?!\/)/;
    const isInternal = exampleLink.test(link) || isInternalLink.test(link);

    if (isInternal) {
      return (
        <Link className="text-base text-primary underline" href={link}>
          {children}
        </Link>
      );
    }

    return (
      <a
        rel="noopener nofollow noreferrer external"
        target="_blank"
        className="text-base text-primary underline"
        href={link}
      >
        {children}
      </a>
    );
  },
  blockquote: ({ children }: any) => <blockquote className="border-primary-500 border-l-4 pl-4">{children}</blockquote>,
  img: ({ src, height, width, altText }: any) => (
    <Image src={src!} width={1500} height={1500} className="" alt={altText!} loading="lazy" />
  ),
};
